<template>
  <div>
    <!-- Filters -->
    <!-- <products-list-inventory-filters
      :status-filter.sync="statusInventoryFilter"
      :status-options="statusInventoryOptions"
    />-->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="flex justify-between">
          <!-- Search -->
          <b-col cols="12" md="4">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Tìm kiếm..."
            />
          </b-col>
          <!-- Per Page -->
          <b-col cols="12" md="4">
            <SelectInventory @onChangeInventory="handleChangeInventory" />
          </b-col>
          <div class="d-flex justify-content-end col-md-4" cols="12" md="4">
            <b-button @click="handleAddBill" variant="primary" class="mb-2">{{
              $t("lbl_import_receiving")
            }}</b-button>
          </div>
          <!-- <b-col cols="12" md="3">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="statusInventoryFilter"
              :options="statusInventoryOptions"
              class="w-100"
              :reduce="val => val.value"
              placeholder="Trạng thái hàng hoá"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <feather-icon icon="ChevronDownIcon" size="20" />
                </span>
              </template>
            </v-select>
          </b-col>-->

          <!-- <b-col class="d-flex justify-content-end" cols="12" md="8">
          </b-col>-->
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refProductsTable"
          class="position-relative"
          :items="fetchPharmaProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
          :sort-desc.sync="isSortDirDesc"
          @row-clicked="handleViewDetail"
          tbody-class="cursor-pointer"
        >
          <template #cell(sku)="data">
            <span class="text-capitalize">{{
              ` #${data.item.product && data.item.product.sku}`
            }}</span>
          </template>
          <template #cell(name)="data">
            <span class="text-capitalize">{{
              data.item.product && data.item.product.name
            }}</span>
          </template>
          <template #cell(unit_id)="data">
            <span class="text-capitalize">{{ getUnit(data.item) }}</span>
          </template>
          <template #cell(registration_number)="data">
            <span class="text-capitalize">{{
              data.item.product && data.item.product.registration_number
            }}</span>
          </template>
          <template #cell(supplier)="data">
            <span class="text-capitalize">{{
              getSupplierLabel(data.item)
            }}</span>
          </template>
          <template #cell(amount)="data">
            <span class="text-capitalize">{{
              data.item && data.item.qty
            }}</span>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row class="flex justify-between">
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của
              {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <div cols="12" md="8" class="flex items-center">
            <div>
              <label>Hiển thị</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mục</label>
            </div>
            <!-- Pagination -->

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-1 ml-2"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BFormCheckbox,
  BBadge,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
// import TagsListAddNew from '@/components/tagManager/TagsListAddNew.vue'
// import TagsListEdit from '@/components/tagManager/TagsListEdit.vue'
import moment from "moment";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useProductsList from "./productInventory";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import ProductsListInventoryFilters from "@/components/productInventory/ProductsListInventoryFilters.vue";
import SelectInventory from "@/components/SelectInventory/index.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    SelectInventory,
    vSelect,
  },
  created() {},
  computed: {
    ...mapGetters("ecomInventory", ["statusInventoryOptions"]),
  },
  methods: {
    mapBrand(data) {
      if (this.productBrands.find((x) => x.value === data)) {
        return this.productBrands.find((x) => x.value === data).label;
      } else {
        console.log(data);
        return "None";
      }
    },
    mapCategory(data) {
      if (this.productCategories.find((x) => x.value === data)) {
        return this.productCategories.find((x) => x.value === data).label;
      } else {
        console.log(data);
        return "None";
      }
    },
    handleAddBill() {
      const routerName = "AddReceiving";
      this.$router.push({ name: routerName });
    },
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 2000);
    },
    switchIsShow(tag) {
      const payload = tag;
      // eslint-disable-next-line prefer-destructuring
      payload.is_show = !tag.is_show;
      store
        .dispatch("product/updateTags", { id: tag.id, tag: payload })
        .then(() => {
          this.refreshTable();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update show success",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error update show",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showEditBar(data) {
      this.dataTag = {
        name: data.name,
        is_show: {
          label: this.statusOptions.find((e) => e.value === data.is_show).label,
          value: data.is_show,
        },
        id: data.id,
      };
      this.isEditTagsSidebarActive = true;
    },
    formatDate(time) {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this tags.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            store.dispatch("product/deleteTags", { id }).then(() => {
              this.refreshTable();
            });
          }
        });
    },
    formatExpiredDate(data) {
      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    },
    getActiveIngredient(data) {
      return data?.product_info?.active_ingredient_info?.name || "";
    },
    getUnit(data) {
      return data?.product_variant?.product_unit?.name || "";
    },
    getSupplierLabel(data) {
      return data?.product_info?.supplier_info?.supplier_name || "";
    },
    handleChangeInventory(data) {
      this.inventoryInfo = data;
    },
    handleViewDetail(data) {
      const wareHouseProductId = data?.id;
      this.$router.push(`/inventory-product/${wareHouseProductId}`);
    },
  },

  setup() {
    const dataTag = ref({});
    const {
      timeout,
      showTable,
      fetchPharmaProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsTable,
      refetchData,
      productBrands,
      productCategories,
      statusInventoryFilter,
      inventoryInfo,
      inventories,
    } = useProductsList();
    return {
      timeout,
      showTable,
      // Sidebar
      dataTag,
      fetchPharmaProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsTable,
      refetchData,
      productBrands,
      productCategories,
      statusInventoryFilter,
      inventoryInfo,
      inventories,

      // Filter
      avatarText,

      // Extra Filters
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
